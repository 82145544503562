import * as firebase from 'firebase/app'
import { AnexoCotacaoCloud } from './anexoCotacaoCloud'
import { CompradorCotacaoCloud } from './compradorCotacaoCloud'
import { FornecedorCotacaoCloud } from './fornecedorCotacaoCloud'

export enum StatusCotacaoCloud {
  Aberta,
  EmAprovacao,
  PendenteEncerramento,
  Encerrada,
}

export enum StatusSincronizacaoCloud {
  Sincronizada,
  Deletada,
}
export interface FornecedoresConvidadosCloud {
  email: string
  nome: string
}
export interface FornecedoresResponderamCloud {
  email: string
  nome: string
}

export interface CotacaoCloud {
  id: string
  idCompany: string
  codigo: number
  filial: string
  descricao: string
  dataLimite: Date | firebase.default.firestore.Timestamp
  codigoListaCondicaoPagamento: number // padrão da condição de pagamento
  comprador: CompradorCotacaoCloud
  status: StatusCotacaoCloud
  statusSincronizacaoCloud: StatusSincronizacaoCloud
  idOrigin?: number
  identificador?: string
  anexoCotacao?: AnexoCotacaoCloud[]
  fornecedoresConvidados?: FornecedoresConvidadosCloud[]
  fornecedoresResponderam?: FornecedoresResponderamCloud[]
  dataResposta?: Date | firebase.default.firestore.Timestamp
  fornecedoresCotacao?: FornecedorCotacaoCloud[],
  rodada?: number
}
